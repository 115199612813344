import React from "react";

const Map = () => {
    return (
        <div>
            <div>
              
        </div>
        </div >
    )
}

export default Map;