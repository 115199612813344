import React from "react";
import Userprofile from "../../components/Profile/Userprofile";
const Profile=()=>{
    return(
        <div>
            <Userprofile></Userprofile>

        </div>
    )
}

export default Profile;