import React from 'react'

const Chat = () => {
    return (
        <div>
            <div style={{ position: "absolute", bottom: "5%", right: "3%", position: "fixed", backgroundColor: "black", padding: 10, borderRadius: 5 }}>
                <span><a href="https://wa.me/8340175751"><i style={{
                    color: "red", fontSize: 30
                }} class="fa-brands fa-whatsapp"></i></a></span>
            </div>
        </div>
    )
}

export default Chat;