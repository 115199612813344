import React from "react";
import Roomslist from "../../components/Roomslist/Roomslist";

const Rooms=()=>{
    return(
        <div>
           <Roomslist></Roomslist>
        </div>
    )
}

export default Rooms;