import React from "react";
import Roomsinglenav from "../../components/Roomsinglenav/Roomsinglenav";

const Roomsingle=()=>{
    return(
        <div>
          <Roomsinglenav></Roomsinglenav>       
        </div>
    )
}

export default Roomsingle;